import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { createPortal } from "react-dom";
import { useForm, useFormContext } from "react-hook-form";
import { SurveyStateContext } from "../SurveyContext";
import { useAppContext } from "../../../context/AppContext";
import _ from "lodash";

import { fieldIsRequiredMessage } from "../../../constants/ValidationErrorConstants";
import { SearchForPharmacy } from "../../../services/SearchForPharmacy";

import { statesArray } from "../../../constants/States";
import "./SurveyPharmacyField.scss";
import { CheckmarkSvg } from "../../svgs/CheckmarkSvg";
import { colors } from "../../../theme";
import Loader from "../../Loader";
import DauLogoImage from "../../../assets/images/DauLogo.png";
import { IoIosArrowDown } from "react-icons/io";
import { zipRegex } from "../../../utils/validation";

export const SurveyPharmacyField = (props) => {
  const {
    question,
    register,
    errors,
    pharmacyHeightHandler,
    trigger,
    mainFormSetValue,
  } = props;
  const { user } = useAppContext();
  const userPharmacies = user?.pharmacies;

  const {
    handleSubmit,
    register: pharmacyRegister,
    formState: { errors: pharmacyErrors, isValid },
    setValue,
    watch,
  } = useForm({
    mode: "onSubmit",
  });

  const pharmacyRegisteredName = "pharmacyId";

  const context = useContext(SurveyStateContext);
  const [answers, setAnswers] = context;

  const [pharmaciesAreShown, setPharmaciesAreShown] = useState(false);

  const [
    pharmaciesAreShownAfterBackButton,
    setPharmaciesAreShownAfterBackButton,
  ] = useState(!!answers.pharmaciesList);

  const [pharmacyRequestBody, setPharmacyRequestBody] = useState({});
  const [selectedPharmacyId, setSelectedPharmacyId] = useState(
    answers.pharmacy_id_question || null
  );

  const {
    data: fetchedPharmacies,
    isLoading,
    isError,
  } = useQuery(
    [`pharmacies/search`, { pharmacyRequestBody }],
    () => SearchForPharmacy(pharmacyRequestBody),
    { enabled: pharmaciesAreShown }
  );

  const searchForPharmacy = (data) => {
    setPharmacyRequestBody({
      state: data.pharmacyState,
      city: data.pharmacyCity,
      name: data.pharmacyName,
      zip: data.pharmacyZip,
    });
    setPharmaciesAreShown(true);
  };

  const pharmacyHasBeenChosen = () => {
    if (watch(pharmacyRegisteredName)) {
      return true;
    } else {
      return "Please search for a pharmacy";
    }
  };

  const PharmacySection = ({ pharmacies, pharmacy, userPharmacy }) => {
    const { id, name, mapping_id } = pharmacy;

    const pharmacyId =
      typeof pharmacy?.mapping_id === "number" &&
      !_.isUndefined(pharmacy?.mapping_id)
        ? pharmacy?.mapping_id
        : pharmacy?.id;

    const isPrimary = selectedPharmacyId === pharmacyId;
    const primaryClasses = isPrimary ? "bg-primary text-darkSecondary" : "";

    const handleClick = () => {
      setSelectedPharmacyId(pharmacyId);
      setValue(pharmacyRegisteredName, pharmacyId, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      mainFormSetValue(question.searchable_tag, pharmacyId);

      if (!userPharmacy) {
        setAnswers({ ...answers, pharmaciesList: pharmacies });
      }

      trigger(pharmacyRegisteredName);
    };

    const renderAddress = () => {
      if (userPharmacy) {
        const { address } = pharmacy;
        const addressKeys = ["line_1", "line_2", "city", "state", "zip"];
        return addressKeys.map((key) => <p key={key}>{address[key]}</p>);
      } else {
        const addressKeys = ["address1", "city", "state", "zip_code"];
        return addressKeys.map((key) => (
          <p key={key}>
            {key === "zip_code" ? pharmacy[key]?.split("-")[0] : pharmacy[key]}
          </p>
        ));
      }
    };

    return (
      <div
        className={`mb-[1rem] sm:mb-[2rem] flex flex-col px-4 py-5 rounded-3xl cursor-pointer transition-all duration-200 ${primaryClasses}`}
      >
        <div className="flex flex-col sm:flex-row relative">
          <div className="flex flex-col lg:flex-row space-x-[1.37rem]">
            <label
              className={`sm:text-[1.18rem] 2xl:text-2xl font-medium ${primaryClasses}`}
            >
              {name}
            </label>
            {!isPrimary && (
              <p
                className="text-mediumPrimary hidden lg:flex text-base xl:text-[1.12rem] font-inter underline underline-offset-2"
                onClick={handleClick}
              >
                Mark as default pharmacy
              </p>
            )}
          </div>
          <label className="custom-radio">
            <input
              type="radio"
              {...register(question.searchable_tag, {
                required: fieldIsRequiredMessage(
                  question.validation_field_name
                ),
              })}
              value={pharmacyId}
              checked={isPrimary}
              className="hidden"
            />
            {isPrimary && (
              <span className="absolute top-2 right-5">
                <CheckmarkSvg fillColor={colors.secondary} />
              </span>
            )}
          </label>
        </div>
        <div
          className={`flex text-[1rem] 2xl:text-[1.25rem] flex-wrap gap-[5px] ${
            isPrimary ? "text-white" : ""
          }`}
        >
          {renderAddress()}
        </div>
        {!isPrimary && (
          <p
            className="text-mediumPrimary font-bodyCopy lg:hidden text-[15px] underline underline-offset-2"
            onClick={handleClick}
          >
            Mark as default pharmacy
          </p>
        )}
      </div>
    );
  };

  const pharmacyInputDivs = (pharmacies) => (
    <div className="w-full bg-white px-[1.25rem] py-[1.5rem] rounded-2xl shadow-md lg:rounded-t-none">
      <p className="mb-[2rem] text-lg 2xl:text-2xl text-inputGray font-bold">
        Search Results
      </p>
      <div className="pl-[0.7rem] grid grid-cols-1 md:grid-cols-2 md:gap-x-5 justify-between">
        {pharmacies.map((pharmacy, index) => (
          <PharmacySection
            key={index}
            pharmacies={pharmacies}
            pharmacy={pharmacy}
          />
        ))}
      </div>
    </div>
  );

  const renderPharmaciesList = () => {
    if (pharmaciesAreShownAfterBackButton) {
      const pharmacies = answers.pharmaciesList;
      return pharmacyInputDivs(pharmacies);
    }

    if (isLoading) {
      return <Loader />;
    }

    if (isError) {
      return <p>Error fetching pharmacy data.</p>;
    }

    if (!fetchedPharmacies.length) {
      return <div>No pharmacies found</div>;
    }

    return pharmacyInputDivs(fetchedPharmacies);
  };

  const renderErrors = (fieldName) => {
    if (pharmacyErrors && pharmacyErrors[fieldName]) {
      return (
        <small className="flex justify-center text-red-700 text-[0.8rem]">
          {pharmacyErrors[fieldName].message}
        </small>
      );
    }
  };

  const getOrCreateHost = () => {
    const host = document.getElementById("portal");

    if (host) {
      return host;
    }

    const el = document.createElement("div");
    el.setAttribute("id", "portal");
    document.body.appendChild(el);

    return el;
  };

  const handleSearchButtonClick = (data) => {
    searchForPharmacy(data);
    setPharmaciesAreShownAfterBackButton(false);
  };

  // Function to calculate and set the form height
  const calculateFormHeight = () => {
    const form = document.getElementById("pharmacy-form");
    if (form) {
      const height = form.clientHeight;
      pharmacyHeightHandler(height);
    }
  };

  useEffect(() => {
    calculateFormHeight();
    window.addEventListener("resize", calculateFormHeight);
    return () => {
      window.removeEventListener("resize", calculateFormHeight);
    };
  }, []);

  useEffect(() => {
    calculateFormHeight();
  }, [fetchedPharmacies]);

  return createPortal(
    <form
      id="pharmacy-form"
      className="w-full max-w-[87.62rem] px-[5%] lg:px-[2%] left-1/2 transform -translate-x-1/2 absolute top-[27rem] md:top-[35.5rem] lg:top-[41rem] xl:top-[41.5rem] 2xl:top-[42.5rem] flex flex-col items-center"
    >
      <div
        className={`px-[5%] bg-white py-[1.2rem] lg:py-[3.12rem] rounded-2xl border-[1px] border-lightGray shadow-md mb-[2.5rem] lg:mb-0 ${
          pharmaciesAreShown && "lg:rounded-b-none"
        } lg:border-b-0`}
      >
        <div className="flex w-full font-medium text-[15px] lg:text-[1.5rem] mb-[1.25rem] lg:mb-[3.12rem]">
          <h2>Pharmacy Search</h2>
        </div>
        {userPharmacies && (
          <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-x-2">
            {userPharmacies?.map((userPharmacy, index) => (
              <PharmacySection
                key={index}
                pharmacies={userPharmacies}
                pharmacy={userPharmacy}
                userPharmacy={true}
              />
            ))}
          </div>
        )}
        <div className="flex">
          <fieldset className="w-full flex flex-col lg:flex-row lg:items-end gap-[1.25rem]">
            <div className="w-full grid grid-cols-6 lg:grid-cols-9 xl:grid-cols-12 gap-2 md:gap-4 lg:gap-3 text-[0.75rem] sm:text-base 2xl:text-2xl">
              <div className="mb-[1.25rem] md:mb-0 col-span-2 lg:col-span-1">
                <label className="text-[13px] lg:text-[1.06rem] mb-[9px]">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="zip"
                  {...pharmacyRegister("pharmacyZip", {
                    required: "Zip is required",
                    pattern: {
                      value: zipRegex,
                      message: "Invalid Format",
                    },
                  })}
                  placeholder="Zip"
                  className="w-full outline-none mb-[0.3rem] md:mb-[0.5rem] p-2 border-[1px] border-borderGraySmall lg:border-borderGray rounded-[10px] text-[0.75rem] sm:text-base lg:py-[1rem]"
                />
                {renderErrors("pharmacyZip")}
              </div>
              <div className="mb-[1.25rem] md:mb-0 col-span-2">
                <label className="text-[13px] lg:text-[1.06rem] mb-[9px]">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  {...pharmacyRegister("pharmacyCity")}
                  placeholder="City"
                  className="w-full outline-none mb-[0.3rem] md:mb-[0.5rem] p-2 border-[1px] border-borderGraySmall lg:border-borderGray rounded-[10px] text-[0.75rem] sm:text-base lg:py-[1rem]"
                />
                {renderErrors("pharmacyCity")}
              </div>
              <div className="col-span-2">
                <label className="text-[13px] lg:text-[1.06rem] mb-[9px]">
                  State
                </label>
                <div className="relative">
                  <select
                    {...pharmacyRegister("pharmacyState")}
                    className="w-full outline-none mb-[0.3rem] cursor-pointer md:mb-[0.5rem] p-2 border-[1px] border-borderGraySmall lg:border-borderGray bg-transparent rounded-[10px] text-[0.75rem] sm:text-base lg:py-[1rem]"
                  >
                    <option value="">State</option>
                    {statesArray.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <IoIosArrowDown className="absolute right-3 top-1/2 transform -translate-y-[12px] pointer-events-none h-[20px]" />
                </div>
              </div>
              <div className="col-span-6 lg:col-span-4">
                <label className="text-[13px] lg:text-[1.06rem] mb-[9px]">
                  Pharmacy
                </label>
                <input
                  type="text"
                  name="pharmacyName"
                  {...pharmacyRegister("pharmacyName")}
                  placeholder="Pharmacy Name"
                  className={`outline-none mb-[0.3rem] md:mb-[0.5rem] p-2 w-full text-[0.75rem] sm:text-base lg:py-[1rem] border-[1px] border-borderGraySmall lg:border-borderGray rounded-[10px]`}
                />
                {renderErrors("pharmacyName")}
              </div>
              <div className="flex items-end col-span-4 lg:col-start-4 xl:col-start-10 hidden lg:flex">
                <button
                  type="submit"
                  className={`w-[17.5rem] text-base 2xl:text-2xl 
                            text-center bg-secondary text-darkPrimary
                            font-bold py-2 px-24 rounded-full border-l-2
                          border-white mb-[1rem] ${!isValid && "opacity-60"}`}
                  form="pharmacy-form"
                  onClick={handleSubmit(handleSearchButtonClick)}
                >
                  SEARCH
                </button>
              </div>
              {pharmaciesAreShown && (
                <div className="col-span-6 lg:col-span-9 xl:col-span-12 relative h-full flex items-center justify-center hidden lg:grid mt-[3.12rem]">
                  <div className="w-full absolute h-[1px] bg-secondary top-1/2 translate-y-[-50%]" />
                  <img
                    src={DauLogoImage}
                    alt=""
                    className="h-[3.12rem] w-[3.12rem] lg:h-[5rem] lg:w-[5rem] xl:h-[6.25rem] xl:w-[6.25rem]"
                  />
                </div>
              )}
              <input
                type="hidden"
                name={pharmacyRegisteredName}
                form="parent-form"
                {...register(pharmacyRegisteredName, {
                  validate: pharmacyHasBeenChosen,
                })}
              />
            </div>
          </fieldset>
        </div>
      </div>
      <div className="flex items-end col-span-4 lg:col-start-4 xl:col-start-10 lg:hidden">
        <button
          type="submit"
          className={`w-[17.5rem] text-base 2xl:text-2xl 
                            text-center bg-secondary text-darkPrimary
                            font-bold py-2 px-24 rounded-full border-l-2
                          border-white mb-[1rem] ${!isValid && "opacity-60"}`}
          form="pharmacy-form"
          onClick={handleSubmit(handleSearchButtonClick)}
        >
          SEARCH
        </button>
      </div>
      {pharmaciesAreShown && (
        <div className="col-span-12 relative h-full flex items-center justify-center lg:hidden mb-4">
          <div className="w-full absolute h-[1px] bg-secondary top-1/2 translate-y-[-50%]" />
          <img
            src={DauLogoImage}
            alt=""
            className="h-[50px] w-[50px] lg:h-[80px] lg:w-[80px] xl:h-[100px] xl:w-[100px]"
          />
        </div>
      )}
      {(pharmaciesAreShown && renderPharmaciesList()) ||
        (pharmaciesAreShownAfterBackButton && renderPharmaciesList())}
      {errors[pharmacyRegisteredName] && (
        <small className="flex justify-center text-red-700">
          {errors[pharmacyRegisteredName].message}
        </small>
      )}
    </form>,
    getOrCreateHost(),
    calculateFormHeight()
  );
};

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAppContext } from "../../context/AppContext";
import Modal from "../Modal";
import SectionCard from "../Dashbaord/SectionCard";
import { Fade } from "react-awesome-reveal";
import { NavLink } from "react-router-dom";
import {
  getPendingExternalSurveys,
  markSurveyCompleted,
} from "../../utils/survey";
import DashboardHeader from "../../pages/Dashboard/DashboardHeader";

const MDISurveySection = ({
  pendingSurveys,
  isOnboarding,
  setPendingSurveys,
}) => {
  const { externalSurveys, setExternalSurveys } = useAppContext();
  const [modalContent, setModalContent] = useState(false);

  const MDISurvey = ({ externalSurvey }) => {
    useEffect(() => {
      const surveyCompletedHandler = async (event) => {
        if (event.data.event === "finish") {
          markSurveyCompleted(
            externalSurveys,
            setExternalSurveys,
            modalContent
          );
          closeModal();
        }
      };
      window.addEventListener("message", surveyCompletedHandler, false);

      return () => {
        window.removeEventListener("message", surveyCompletedHandler, false);
      };
    }, []);

    return (
      <iframe
        id="mdi-survey-iframe"
        title="MDI Survey"
        src={`${externalSurvey?.survey_link}&fullscreen=true`}
        allowFullScreen
        allow="camera; microphone"
        className="w-full h-full rounded-md"
      ></iframe>
    );
  };

  const closeModal = () => {
    setModalContent(false);
  };

  const SurveyCard = ({ externalSurvey }) => {
    return (
      <div className="w-full h-full flex flex-col justify-between items-start pb-[16px]">
        <h2 className="text-[24px]">HSV intake</h2>
        <button
          type="button"
          onClick={() => setModalContent(externalSurvey)}
          className={`bg-secondary text-base
               text-darkPrimary py-2 absolute bottom-10 right-10
                rounded-full border-l-2 border-white w-[70%] md:w-[10rem]`}
        >
          Get Started
        </button>
      </div>
    );
  };

  return (
    <div className="w-full h-full">
      <DashboardHeader pendingSurveys={pendingSurveys} />
      {modalContent && (
        <Modal
          content={<MDISurvey externalSurvey={modalContent} />}
          onClose={closeModal}
          className="h-full"
        />
      )}
      {/* TODO: Make opening up MDI survey directly in an onboarding process dynamic */}
      {isOnboarding &&
      !pendingSurveys[pendingSurveys?.length - 1]?.completed_at ? (
        <MDISectionOnboarding
          pendingSurveys={pendingSurveys}
          setPendingSurveys={setPendingSurveys}
        />
      ) : (
        <div className="w-[90%] mx-auto pt-[15px] md:pt-[3rem] pb-[50px]">
          <h1 className="mb-[38px] md:mb-[73px] text-[30px] text-start font-bodyCopy">
            Complete the following Pending Survey
            {pendingSurveys?.length > 1 && "s"}!
          </h1>
          <Fade bottom>
            <div className="w-full md:flex md:flex-wrap">
              {pendingSurveys?.map((externalSurvey, index) => (
                <div className="flex flex-col items-center md:flex-row">
                  <SectionCard
                    key={index}
                    content={<SurveyCard externalSurvey={externalSurvey} />}
                    className="relative !h-[200px] !md:h-[200px]"
                  />
                </div>
              ))}
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default MDISurveySection;

const MDISectionOnboarding = ({ pendingSurveys, setPendingSurveys }) => {
  const { externalSurveys, setExternalSurveys } = useAppContext();

  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const survey = pendingSurveys[pendingSurveys?.length - 1];

  useEffect(() => {
    const surveyCompletedHandler = async (event) => {
      if (event.data.event === "finish") {
        // markSurveyCompleted(externalSurveys, setExternalSurveys, survey);
        setSurveyCompleted(true);
      }
    };
    window.addEventListener("message", surveyCompletedHandler, false);

    return () => {
      window.removeEventListener("message", surveyCompletedHandler, false);
    };
  }, []);

  return (
    <div className="w-full h-full max-h-[85vh] overflow-y-scroll lg:overflow-y-auto lg:max-h-none lg:h-full flex items-center justify-center p-5">
      {surveyCompleted ? (
        <div className="w-full md:w-[70%] h-full md:h-[70%] bg-white rounded-md shadow-lg flex flex-col items-center justify-center">
          <h1 className="mb-[38px] text-[30px] text-start font-bodyCopy">
            You're all set!
          </h1>
          <p className="w-7/12 mb-[48px]">
            You're Information has been sent directly to your doctor. Over the
            next couple of hours you should be receiving more information
            directly from your doctor.
          </p>
          <button
            onClick={() => {
              markSurveyCompleted(externalSurveys, setExternalSurveys, survey);
              // setPendingSurveys(getPendingExternalSurveys(externalSurveys));
            }}
            className={`bg-primary text-sm 
               text-white py-3 px-6 hover:font-bold
                rounded-md border-l-2 border-white mb-10`}
          >
            Continue To Dashbaord
          </button>
        </div>
      ) : (
        <iframe
          id="mdi-survey-iframe"
          title="MDI Survey"
          src={`${survey?.survey_link}&fullscreen=true`}
          allowFullScreen
          className="w-full h-full bg-white rounded-md shadow-md"
        ></iframe>
      )}
    </div>
  );
};

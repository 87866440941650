import { IoIosArrowDown } from "react-icons/io";
import { shippingAddressFields } from "../../../utils/payment";

const FormField = ({
  field,
  statesArray,
  register,
  renderErrors,
  handleBillingSameAsShipping,
  shippingAddressFields,
  index,
  setValue,
  trigger,
}) => {
  const handleInputChange = (e) => {
    setValue(field.name, e.target.value);
    setValue(shippingAddressFields[index].name, e.target.value);
    trigger(field.name);
  };
  return (
    <div className="w-full">
      <div className="flex flex-col items-start mb-[1.5rem]">
        <label className="text-[13px] md:text-[14px] mb-[12px]">
          {field.label}
        </label>
        {field.type === "stateSelector" ? (
          <>
            <div className="relative w-full">
              <select
                className="bg-transparent h-[2.6rem] 2xl:h-[3.75rem] cursor-pointer 2xl:px-[1rem] text-base 2xl:text-base text-inputGray outline-none p-2 w-full border border-borderGraySmall lg:border-borderGray rounded"
                {...register(field.name, field.validation)}
              >
                <option value="">Select</option>
                {statesArray.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <IoIosArrowDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none h-[20px]" />
            </div>
          </>
        ) : (
          <>
            <input
              {...register(field.name, field.validation)}
              type="text"
              placeholder={field.placeholder}
              className="outline-none h-[2.6rem] 2xl:h-[3.75rem] 2xl:px-[1.5rem] text-base 2xl:text-base p-2 w-full border border-borderGraySmall lg:border-borderGray rounded"
            />
          </>
        )}
      </div>
      <div>{renderErrors(field.name)}</div>
    </div>
  );
};

const FormSetBody = ({
  fieldsObject,
  statesArray,
  register,
  renderErrors,
  setValue,
  trigger,
}) => {
  return (
    <>
      {fieldsObject.fullWidthFields.map((field, index) => (
        <FormField
          key={index}
          field={field}
          statesArray={statesArray}
          register={register}
          renderErrors={renderErrors}
          shippingAddressFields={shippingAddressFields.fullWidthFields}
          index={index}
          setValue={setValue}
          trigger={trigger}
        />
      ))}
      <div className="flex justify-between w-full gap-[1rem]">
        {fieldsObject.splitWidthFields.map((field, index) => (
          <FormField
            key={index}
            field={field}
            statesArray={statesArray}
            register={register}
            renderErrors={renderErrors}
            shippingAddressFields={shippingAddressFields.splitWidthFields}
            index={index}
            setValue={setValue}
            trigger={trigger}
          />
        ))}
      </div>
    </>
  );
};

const AddressForm = ({
  fieldsObject,
  statesArray,
  register,
  renderErrors,
  renderBillingFields,
  handleBillingSameAsShipping,
  billing_address_same_as_shipping,
  shippingAddressFields,
  setValue,
  trigger,
}) => {
  return (
    <div className="bg-white flex flex-col w-full items-start px-[1rem] md:px-[4%] py-[1rem] md:py-[2%] border-[#D7D7D7] lg:border-borderGray border-[1px] rounded-[10px] lg:rounded-md mb-[3rem]">
      <h1 className="text-[17px] md:text-[20px] mb-[1.5rem]">
        {fieldsObject.title}
      </h1>

      {fieldsObject.title === "Billing Address" && (
        <div className="mb-[1.5rem] flex gap-[1rem] items-center">
          <input
            {...register(billing_address_same_as_shipping)}
            type="checkbox"
            onClick={handleBillingSameAsShipping}
            className="billing-address-checkbox"
          />
          <label>Same as shipping address</label>
        </div>
      )}

      {fieldsObject.title === "Billing Address" && renderBillingFields && (
        <FormSetBody
          fieldsObject={fieldsObject}
          statesArray={statesArray}
          register={register}
          renderErrors={renderErrors}
          shippingAddressFields={shippingAddressFields}
          setValue={setValue}
          trigger={trigger}
        />
      )}

      {fieldsObject.title !== "Billing Address" && (
        <FormSetBody
          fieldsObject={fieldsObject}
          statesArray={statesArray}
          register={register}
          renderErrors={renderErrors}
        />
      )}
    </div>
  );
};

export default AddressForm;

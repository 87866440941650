import React from "react";
import Slider from "../Slider/Slider";
import Slide from "./Slide";
import DesktopHeroImage1 from "../../assets/images/DesktopHeroImage1.webp";

export const Hero = () => {
  const slidesData = [
    {
      title: (
        <>
          Intimate Wellness, <br />
          <span className="text-secondary">Your Way</span>
        </>
      ),
      description:
        "We offer both pharmacy pickup and discreet home delivery for your prescription!",
    },
    {
      title: (
        <>
          <span className="text-secondary">No</span> Appointment Needed
        </>
      ),
      description: "Access care instantly, whenever you need it.",
    },
    {
      title: (
        <>
          <span className="text-secondary">Personalized</span>
          <br />
          Treatment
        </>
      ),
      description: "Tailored solutions designed just for you.",
    },
    {
      title: (
        <>
          <span className="text-secondary">US-Licensed</span>
          <br />
          Clinicians
        </>
      ),
      description:
        "Expert care you can trust, from professionals across the country.",
    },
    {
      title: (
        <>
          <span className="text-secondary">Secure</span>
          <br className="md:hidden" /> Health Data
        </>
      ),
      description: "Your privacy protected with state-of-the-art encryption.",
    },
  ];
  return (
    <div>
      <div
        id="hero-parent"
        className="flex h-full w-full overflow-hidden p-[1.2rem] md:p-[2rem] lg:p-[2.5rem] xl:p-[3rem] gap-x-[2rem]"
      >
        <div className="h-full w-full md:w-[60%]">
          <Slider borderRadius={{ sm: "1rem" }} className={"rounded-[1rem]"}>
            {slidesData.map((slide) => (
              <Slide title={slide.title} description={slide.description} />
            ))}
          </Slider>
        </div>
        <div id="photo-content" className="w-[39%] hidden md:flex">
          <img
            src={DesktopHeroImage1}
            alt="desktop"
            className="rounded-xl h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

import React, { useRef, useState, useEffect } from "react";
import {
  emailInputTypeEnumString,
  fieldIsRequiredMessage,
} from "../../constants/ValidationErrorConstants";
import { resendOtp } from "../../services/auth";
import { useSurveyState } from "./SurveyContext";
import { useAppContext } from "../../context/AppContext";
import Loader from "../Loader";

export const SurveyPhoneInputField = ({
  question,
  register,
  setError,
  setValue,
  trigger,
  errors,
  unregister,
}) => {
  const cellPhoneRef = useRef();
  const [state, setState] = useSurveyState();
  const { setModalState } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);

  const isPhoneValid = (phone) => {
    if (!question.input_type === emailInputTypeEnumString) {
      return true;
    }
    // Regular expression for basic U.S. phone validation
    const usPhoneNumberRegex = /^\d{3}\d{3}\d{4}$/;
    return usPhoneNumberRegex.test(phone);
  };

  const requestCodeHandler = async () => {
    const phoneNumber = cellPhoneRef.current.value;

    if (isPhoneValid(phoneNumber)) {
      setIsLoading(true);
      try {
        setState((prevState) => ({
          ...prevState,
          setRequestOtpCode: true,
        }));
        await resendOtp(phoneNumber);
        alert("Code Sent!");
      } catch (error) {
        setModalState({
          switch: true,
          message: error?.response?.data?.errors,
          status: "error",
        });
        console.error(error.response);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError(question.searchable_tag, {
        type: "manual",
        message: "Invalid phone number. Format: 8883334444 (no spaces/dashes)",
      });
    }
  };

  const handleCellPhoneChange = (e) => {
    setValue(question.searchable_tag, e.target.value);
    trigger(question.searchable_tag);
  };

  useEffect(() => {
    if (cellPhoneRef.current.value) {
      setValue(question.searchable_tag, cellPhoneRef.current.value);
      trigger(question.searchable_tag);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center">
        <input
          type="text"
          placeholder={question.input_placeholder_text}
          {...register(question.searchable_tag, {
            required: fieldIsRequiredMessage(
              question.validation_field_name || ""
            ),
            validate: (value) =>
              isPhoneValid(value) || "Format: 8883334444 (no spaces/dashes)",
          })}
          className="mb-[0.8rem] md:mb-[1rem] lg:mb-[1.8rem] inline-block outline-none text-[0.75rem] sm:text-base w-full border-[1px] border-borderGraySmall lg:border-borderGray rounded-[5px] lg:rounded-[10px] px-[10px] py-[6px] lg:p-[1.2rem]"
          ref={cellPhoneRef}
          onChange={handleCellPhoneChange}
          defaultValue={state[question.searchable_tag]}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <button
            type="button"
            className="w-full lg:w-auto h-[2rem] md:h-[2.6rem] rounded-md lg:rounded-lg px-3  sm:py-0  bg-primary text-white text-[0.75rem] sm:text-base lg:text-lg"
            onClick={requestCodeHandler}
          >
            {state.setRequestOtpCode ? "Resend Code" : "Send OTP Code"}
          </button>
        )}
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { getMessagesLink } from "../../services/messages";
import Loader from "../../components/Loader";
import { fetchEncounters } from "../../services/encounters";
import { useQuery } from "@tanstack/react-query";
import docChatIcon from "../../assets/icons/doc-user-message.png";
import _ from "lodash";
import { useAppContext } from "../../context/AppContext";
import SectionTitle from "../../components/Dashbaord/SectionTitle";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

const ChatRoom = ({ selectedEncounter, setSelectedEncounter }) => {
  const { user, userTreatments } = useAppContext();

  const { data: encounters } = useQuery({
    queryKey: ["fetchEncounters"],
    queryFn: () => fetchEncounters(user?.id),
  });

  useEffect(() => {
    if (encounters?.length) {
      setSelectedEncounter(encounters[0]);
    }
  }, [encounters]);

  const ChatBox = () => {
    return (
      <div
        className={`lg:block h-full w-full lg:w-[57%] lg:max-w-[56.8rem] flex-1 ${
          selectedEncounter ? "block" : "hidden"
        }`}
      >
        {!_.isEmpty(selectedEncounter) ? (
          <iframe
            title="Embedded Content"
            src={selectedEncounter?.messaging_link}
            allowFullScreen
            className="h-full w-full"
          ></iframe>
        ) : (
          <div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 
          -translate-y-1/2 flex items-center justify-center opacity-60"
          >
            <img src={docChatIcon} className="w-7/5" />
          </div>
        )}
      </div>
    );
  };

  const EncountersList = () => {
    return (
      <>
        {encounters?.length > 0 && (
          <div
            className={`md:h-full w-full lg:w-[31%] flex flex-col items-start py-[1.25rem] lg:border-[1px] lg:border-ordersBorder rounded-[5px] ${
              selectedEncounter ? "hidden" : "block"
            } lg:block`}
          >
            <p className="text-[20px] text-primary font-medium mb-[5px] px-[1rem] 2xl:px-[1.25rem] hidden lg:block">
              Your Messages
            </p>
            {encounters?.map((encounter) => {
              const isSelected = encounter?.id === selectedEncounter?.id;
              const treatmentName =
                _.capitalize(
                  _.find(userTreatments, {
                    treatment_id: encounter?.treatment_id,
                  })?.treatment_name
                ) + " Chatroom";
              return (
                <div className="flex w-full items-center">
                  {isSelected && (
                    <div className="h-14 w-[5px] bg-secondary rounded-r-full" />
                  )}
                  <div
                    className={`w-full py-[1.1rem] 2xl:py-[1.56rem] text-start flex items-center ml-[1rem] xl:ml-[1.25rem] mr-[1.2rem] 2xl:mr-[1.87rem] cursor-pointer border-b-[1px] border-b-ordersBorder`}
                    onClick={() => setSelectedEncounter(encounter)}
                  >
                    <p className="text-[1rem] 2xl:text-[1.12rem] w-full font-semibold ">
                      {treatmentName}
                    </p>
                    <div className="bg-primary rounded-full p-[2px] self-start cursor-pointer mt-2 md:mt-5 mr-3 lg:hidden">
                      <MdOutlineKeyboardArrowRight
                        size={24}
                        className="text-secondary"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col md:flex-row gap-x-[1rem] xl:gap-x-[1.87rem] w-full h-full">
      <EncountersList />
      <ChatBox />
    </div>
  );
};

const Messages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEncounter, setSelectedEncounter] = useState();
  const getMessagesIFrame = async () => {
    try {
      const messagesLink = await getMessagesLink();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getMessagesIFrame();
  }, []);

  return (
    <div className="md:px-[1rem] 2xl:px-[1.875rem] pb-[1.75rem]">
      <div className="flex mb-[1rem] lg:mb-0 ">
        {selectedEncounter && (
          <div
            className="bg-primary rounded-full p-[2px] self-start cursor-pointer mt-2 md:mt-5 mr-3 lg:hidden"
            onClick={() => setSelectedEncounter("")}
          >
            <MdOutlineKeyboardArrowLeft size={24} className="text-secondary" />
          </div>
        )}
        <SectionTitle
          title={selectedEncounter ? "Messages" : "Messaging Center"}
        />
      </div>
      <div className="h-[85vh] bg-white flex items-center justify-center">
        {isLoading ? (
          <Loader />
        ) : (
          <ChatRoom
            setSelectedEncounter={setSelectedEncounter}
            selectedEncounter={selectedEncounter}
          />
        )}
      </div>
    </div>
  );
};

export default Messages;

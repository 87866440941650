import React from "react";
import DauchiLogo from "../../assets/images/DauLogo.png";
import HeroSlide from "./HeroSlide";
import Slider from "../Slider/Slider";

const TreatmentHero = ({ content, surveyPath, common }) => {
  const { name, title, text, image, eclipse } = content;

  const slidesData = [
    {
      title,
      styledTitle: title,
      text,
    },
    {
      styledTitle: (
        <p>
          <span className="text-secondary">NO</span> Appointment Needed
        </p>
      ),
      title: "NO Appointment Needed",
      text: `Access ${name} care instantly, whenever you need it.`,
    },
    {
      styledTitle: (
        <p>
          <span className="text-secondary">Personalized</span> Treatment
        </p>
      ),
      title: "Personalized Treatment",
      text: `Tailored ${name} solutions designed just for you.`,
    },
    {
      styledTitle: (
        <p>
          <span className="text-secondary">US-Licensed</span> Clinicians
        </p>
      ),
      title: "US Licensed Clinicians",
      text: "Expert care you can trust, from professionals across the country.",
    },
    {
      styledTitle: (
        <p>
          <span className="text-secondary">Secure</span> Health Data
        </p>
      ),
      title: "Secure Health Data",
      text: "Your privacy protected with state-of-the-art encryption.",
    },
  ];

  const renderAdditionalInfo = () => {
    return (
      <>
        {" "}
        {slidesData.slice(1).map((slide, index) => (
          <div
            className={`w-full flex justify-center items-center`}
            key={index}
          >
            <span className="w-[60%] text-center">{slide.title}</span>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="w-full relative bg-primary md:bg-transparent">
      <div className="relative p-[1rem] xl:p-[1.87rem] w-full h-full">
        <div className="w-full bg-treatmentHeroBackground overflow-hidden rounded-[1rem] lg:rounded-[3.12rem] text-start md:pt-[1.8rem] 2xl:pt-[2.12rem] md:p-[2rem] md:pb-[2.5rem] xl:pb-[2.5rem] 2xl:pb-[3.25rem] md:px-[2rem] lg:px-[3rem] xl:px-[4.5rem] relative  flex justify-between">
          <div className="relative z-40 w-full md:w-[56%]">
            <Slider
              className={
                "rounded-[1rem] md:rounded-[3.5rem] xl:rounded-[6.43rem]"
              }
            >
              {slidesData.map((slide) => (
                <HeroSlide
                  title={slide.styledTitle}
                  content={slide.text}
                  surveyPath={surveyPath}
                />
              ))}
            </Slider>
          </div>
          <img
            src={image}
            className="absolute z-30 object-cover inset-0 w-full h-full md:static md:h-[240px] lg:h-[340px] 2xl:h-[500px] md:w-[240px] lg:w-[340px] 2xl:w-[500px] md:rounded-full"
            alt="Dauchi Treatment Banner"
          />
          <img
            src={common.eclipse}
            alt="hero-eclipse"
            className="absolute z-20 top-0 left-0 w-full h-1/2 scale-150"
          />
        </div>
      </div>
      <div className="md:hidden p-[1rem] pt-[4px] pb-[2.65rem] w-full">
        <div className="bg-lightPrimary w-full grid grid-cols-2 gap-y-[4.3rem] rounded-[1rem] py-[2.8rem] relative text-base text-white font-medium overflow-hidden">
          {renderAdditionalInfo()}
          <div className="absolute inset-0">
            <div className="absolute z-10 top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%]">
              <img
                src={DauchiLogo}
                className="w-[4rem] h-[4rem]"
                alt="Dauchi"
              />
            </div>
            <div className="h-full bg-secondary w-[1px] absolute left-1/2 translate-x-[-50%]" />
            <div className="h-[1px] bg-secondary w-full absolute top-1/2 translate-y-[-50%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentHero;

import { notificationIcon, status_code } from "../../../utils/dashboard";
import SectionCard from "../SectionCard";
import WideSectionCard from "../WideSectionCard";
import { useAppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import linkIcon from "../../svgs/link-icon.svg";
import mailIcon from "../../../assets/icons/inbox-solid.svg";
import listGrayIcon from "../../../assets/icons/list-gray.svg";
import _ from "lodash";
import { NavLink } from "react-router-dom";

function HomeSection() {
  const { user, faqs, subscriptions, notifications } = useAppContext();
  const { orders } = user || {};

  const EmptySection = ({ showBtn, icon = mailIcon, className }) => {
    return (
      <div
        className={`flex flex-col w-full h-full mt-5 sm:mt-20 items-center justify-center ${className}`}
      >
        <img src={icon} className="w-20 h-20 mb-5" />
      </div>
    );
  };

  const notificationContent = () => {
    if (!notifications?.filter((notification) => !notification.read)?.length)
      return <EmptySection />;
    return notifications.map(({ body, notification_type }, index) => (
      <div className="flex mb-[20px] items-center">
        <img
          className="w-[24px] h-[24px]"
          src={notificationIcon[notification_type]}
        />
        <p className="ml-[15px]" key={index}>
          {body}
        </p>
      </div>
    ));
  };

  const quickLinksContent = () => {
    const MAX_FAQ_LENGTH = 50;
    return (
      <div>
        <div className="hidden md:block absolute top-[20px] right-[20px]">
          <Link to={"/dashboard/help"}>
            <img
              src={linkIcon}
              alt="link icon svg"
              className="h-[1.2rem] w-[1.2rem] 
                hover:cursor-pointer hover:h-[1.3rem] 
                hover:w-[1.3rem] transition-all duration-200"
            />
          </Link>
        </div>
        {faqs?.slice(0, 5).map((faq, index) => (
          <div
            className="flex mb-[20px] items-center hover:underline underline-offset-2"
            key={index}
          >
            <HashLink smooth to={`/dashboard/help#${faq.id}`}>
              {faq.question_text.length > MAX_FAQ_LENGTH
                ? `${faq.question_text.slice(0, MAX_FAQ_LENGTH)}...`
                : faq.question_text}
            </HashLink>
          </div>
        ))}
      </div>
    );
  };

  const subscriptionContent = () =>
    subscriptions?.map((subscription, index) => {
      return (
        <div
          className="flex mb-[20px] items-center justify-between"
          key={index}
        >
          <div className="flex flex-col">
            <p>{subscription.treatment_name}</p>
            <p className="font-normal text-[13px]">{subscription.name}</p>
          </div>

          <span
            className={`py-[5px] px-3 text-[12px] text-opacity-40 text-center rounded-full ${
              subscription.active ? "bg-green-200" : "bg-red-300"
            }`}
          >
            {subscription.active ? "Active" : "Inactive"}
          </span>
        </div>
      );
    });

  const ordersContent = () => {
    if (!orders?.length)
      return <EmptySection showBtn icon={listGrayIcon} className="md:mt-10" />;
    const currentOrders = orders?.filter(
      (order) =>
        order?.order_status !== "completed" &&
        order?.order_status !== "cancelled"
    );

    return currentOrders.map((order, index) => (
      <div className="flex mb-[20px] items-center justify-between">
        <div className="ml-[15px]">
          <p key={index}>{order?.title}</p>
          <p key={index}>
            Deliver to: {order?.order_type === "pickup" ? "Pharmacy" : "Home"}
          </p>
        </div>

        <span
          className={`w-[80px] py-[5px] text-[12px] text-opacity-40 text-center rounded-full ${
            status_code[order?.order_status]
          }`}
        >
          {_.replace(order?.order_status, "_", " ")}
        </span>
      </div>
    ));
  };

  const pharmacyContent = () => {
    const primaryPharmacy = _.find(user?.pharmacies, { is_primary: true });
    const { name, address, primary_phone } = primaryPharmacy || {};
    const { line_1, line_2, city, zip } = address || {};
    const addressString = _.compact([line_1, line_2, city, zip]).join(", ");
    return (
      <div className="flex flex-col text-left items-start ml-[15px]">
        {["Your current pharmacy is:", name, addressString, primary_phone].map(
          (text, index) => (
            <p
              key={index}
              className={`${
                index === 0
                  ? "mb-[25px] sm:mb-[30px]"
                  : "mb-[13px] sm:mb-[15px] underline cursor-pointer"
              }`}
            >
              {text}
            </p>
          )
        )}
      </div>
    );
  };
  return (
    <div className="flex flex-col">
      <div className="md:hidden text-start text-[1.5rem] mt-[15px] text-darkPrimary font-bodyCopy">
        Dashboard
      </div>
      <div className="flex flex-col items-center md:flex-row">
        <WideSectionCard
          title={"Notifications"}
          content={notificationContent()}
          className={"m-[20px] md:m-[27px]"}
        />
        <SectionCard title={"Subscriptions"} content={subscriptionContent()} />
      </div>

      <div className="flex flex-col items-center md:flex-row">
        <SectionCard title={"Orders"} content={ordersContent()} />
        <SectionCard title={"Pharmacy"} content={pharmacyContent()} />
        <SectionCard title={"Quick Links"} content={quickLinksContent()} />
      </div>
    </div>
  );
}

export default HomeSection;

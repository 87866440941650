import { statesArray } from "../constants/States";
import { zipRegex } from "./validation";

export const PharmacyInputFields = [
  {
    name: "zip",
    label: "Zip Code",
    placeholder: "90020",
    type: "text",
    rules: {
      required: "Zip is required",
      pattern: {
        value: zipRegex,
        message: "Invalid Format",
      },
    },
  },
  {
    name: "city",
    label: "City",
    placeholder: "Los Angeles",
    type: "text",
  },
  {
    name: "state",
    label: "State",
    placeholder: "California",
    type: "select",
    options: statesArray,
  },
  {
    name: "name",
    label: "Pharmancy Name",
    placeholder: "CVS",
    type: "text",
  },
  {
    name: "distance",
    label: "Distance (miles)",
    placeholder: "15",
    type: "text",
  },
];
